import React, { useState, useEffect } from "react"
import fetchJsonp from "fetch-jsonp"

require("es6-promise").polyfill()

const SmartFeedJobAlerts = props => {
  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  })

  const [filterData, setFilterData] = useState({})
  const [locationData, setLocationData] = useState([])
  const [regionData, setRegionData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [jobTypeData, setJobTypeData] = useState([])
  const [jobTimeData, setJobTimeData] = useState([])

  const [showGDPR, setShowGDPR] = useState(false)
  const [GDPRStatement, setGDPRStatement] = useState("")
  const [apiResponse, setApiResponse] = useState("NA")

  useEffect(() => {
    if (props.group) {
      if (props.location) {
        getApiData("location", "GetLocationListByGroup", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetRegionListByGroup", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetCategoryListByGroup", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetIndustryListByGroup", setIndustryData)
      }
    } else {
      if (props.location) {
        getApiData("location", "GetAllLocationsListByCompany", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetAllRegionsListByCompany", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetAllCategoriesListByCompany", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetAllIndustriesListByCompany", setIndustryData)
      }
    }

    getGDPRApiData()
  }, [])

  const changeGDPRVal = () => {
    let temp = { ...jaDetails }

    temp.acceptGDPR = !temp.acceptGDPR

    setJaDetails(temp)
  }

  const getApiData = (name, apiCall, setState) => {
    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/" +
        apiCall +
        "?id=" +
        props.apiKey
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(jsonList) {
        let uniqueArray = []

        uniqueArray.push("All")

        jsonList.map((item, i) => {
          if (!uniqueArray.includes(item)) {
            uniqueArray.push(item)
          }
        })

        setState(uniqueArray)
      })
  }

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.apiKey

    if (props.group) {
      gdprID = props.gdprKey
    }

    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=" +
        gdprID
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(gdprStatement) {
        setGDPRStatement(gdprStatement)
      })
  }

  const subscribeToJobAlerts = () => {
    const serialize = function(obj) {
      var str = []
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
        }
      return str.join("&")
    }

    let toPass = {
      CompanyId: props.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.CompanyGroupName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      UnsubscribeLink:
        "https://new-smart-feed.vacancy-filler.co.uk/unsubscribe.html?UnsubscribeID=",
      OptInLink:
        "https://new-smart-feed.vacancy-filler.co.uk/optin.html?OptInID=",
      PostingType: "careers",
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === "All"
          ? ""
          : jaDetails.Location.join("|"),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === "All"
          ? ""
          : jaDetails.Region.join("|"),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === "All"
          ? ""
          : jaDetails.Industry.join("|"),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === "All"
          ? ""
          : jaDetails.Category.join("|"),
      Distance: 15,
      Unit: "Miles",
    }

    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/SubscribeForJobAlert?id=" +
        props.apiKey +
        "&" +
        serialize(toPass)
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        setApiResponse(result)
      })
  }

  const updateField = (name, val) => {
    let temp = { ...jaDetails }

    temp[name] = val

    setJaDetails(temp)
  }

  const addFilter = (label, item) => {
    let temp = { ...jaDetails }

    if (temp[label].includes(item)) {
      temp[label].splice(temp[label].indexOf(item), 1)
    } else {
      if (item === "All") {
        temp[label] = ["All"]
      } else {
        temp[label].push(item)
      }
    }

    setJaDetails(temp)
  }

  const isItemChecked = (label, item) => {
    if (jaDetails[label].includes(item)) {
      return true
    } else {
      return false
    }
  }

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-xs-12 col-md-4 ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div className="vf-alerts-check-wrap">
                <input
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  title={`Select ${label}`}
                  disabled={jaDetails[label].includes("All") && item !== "All"}
                />
                <label className="vf-alerts-check-label" for={label}>
                  {" "}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <div className="VF_Alerts_Wrap umbraco-forms-form mb-3">
      <div className="VF_Alerts_Inner_Wrap umbraco-forms-page">
        <fieldset className="umbraco-forms-fieldset">
          <legend>Your details</legend>

          <div className="row-fluid">
            <div className="umbraco-forms-container col-md-12">
              <div className=" umbraco-forms-field firstname firstname mandatory">
                <label
                  for="cd03910b-c7b0-452e-fec7-0cdae9c5a543"
                  className="umbraco-forms-label"
                >
                  First name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={e => updateField("FirstName", e.target.value)}
                  placeholder={"Email Address"}
                  placeholder={"First Name"}
                  title={"Enter your first name"}
                ></input>
              </div>
              <div className=" umbraco-forms-field lastname lastname mandatory alternating">
                <label
                  for="1931fd20-c97f-41c9-b74c-2e08a5b1e6c3"
                  className="umbraco-forms-label"
                >
                  Last name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={e => updateField("LastName", e.target.value)}
                  placeholder={"Email Address"}
                  placeholder={"Last Name"}
                  title={"Enter your last name"}
                ></input>
              </div>
              <div className=" umbraco-forms-field email perplextextfield mandatory">
                <label
                  for="d3a75ee9-6149-438a-e033-aabe9769ba95"
                  className="umbraco-forms-label"
                >
                  Email <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={e => updateField("EmailAddress", e.target.value)}
                  placeholder={"Email Address"}
                  title={"Enter your email address"}
                ></input>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="umbraco-forms-fieldset">
          <legend>Roles you're interested in</legend>

          <p>Select at least one option</p>

          <div className="row-fluid">
            <strong>Category: </strong>
            <span className="text-danger">*</span>
            <div className="umbraco-forms-container col-md-12">
              {props.category && <>{renderApiData("Category", categoryData)}</>}
            </div>
          </div>
        </fieldset>
        <fieldset className="umbraco-forms-fieldset">
          <legend>Job alert subscription statement</legend>

          <div className="row-fluid">
            <div className="umbraco-forms-container col-md-12">
              <div className="gdprStatement">
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.Statement,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.GDPRCustomMessage,
                  }}
                />
              </div>

              <span className="text-danger">* </span>
              <input
                type="checkbox"
                name="gdpr"
                value="acceptGDPR"
                className="acceptGDPR_Check"
                checked={jaDetails.acceptGDPR}
                onChange={() => changeGDPRVal()}
                title="Agree to the job alert subscription statement"
              />
              <label for="gdpr">
                I have read and understood this statement and I am happy to
                proceed.
              </label>
            </div>
          </div>
        </fieldset>

        <div className="job-alert-actions">
          {GDPRStatement &&
          jaDetails.acceptGDPR === true &&
          jaDetails.Category.length > 0 &&
          jaDetails.EmailAddress !== "" &&
          jaDetails.FirstName !== "" &&
          jaDetails.LastName !== "" ? (
            <div className="subscrube-btn-wrap">
              {apiResponse === "OK" && (
                <span>You have successfully signed up to Job Alerts</span>
              )}
              {apiResponse === "Error" && (
                <span>There was an error, please try again</span>
              )}
              {apiResponse === "Updated" && (
                <span>
                  You have successfully updated your Job Alerts subscription
                </span>
              )}
              {apiResponse === "Pending" && (
                <span>
                  You have successfully signed up to Job Alerts, please check
                  your email inbox to confirm your subscription.
                </span>
              )}
              {apiResponse !== "OK" &&
                apiResponse !== "Updated" &&
                apiResponse !== "Pending" && (
                  <button
                    className="nav-action-sm"
                    onClick={() => subscribeToJobAlerts()}
                  >
                    Subscribe for job alerts
                  </button>
                )}
            </div>
          ) : (
            <div className="subscrube-btn-wrap">
              <button className="nav-action-sm" disabled>
                Please enter your details to subscribe
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SmartFeedJobAlerts
