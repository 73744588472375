import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SmartFeedJobAlerts from "../components/SmartFeedJobAlerts_WT"

require("es6-promise").polyfill()

const NotFoundPage = () => (
  <Layout>
    <SEO title="Subscribe to Job Alerts" />
    <>
      <div className="page__header-container page__header-container--job-alerts-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
            id="listing-top"
          >
            <div className="col-12 col-lg-8 job-alerts-title-wrap">
              <h1>Subscribe to Job Alerts</h1>
              <p>All fields are required unless indicated as optional</p>
            </div>
          </div>
        </div>
      </div>

      <div className="jobalerts-landing">
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <div className="woodland-trust-spacing">
                <div className="col-12 col-lg-8 job-alerts-panel">
                  <SmartFeedJobAlerts
                    setVacancyLoading={false}
                    apiKey={"UAYuqLuj5r7tAvaNW7qSD6CN-xLilM7rkqVED0gmiaY"}
                    group={false}
                    gdprKey=""
                    CompanyGroupName="Healthspan"
                    VacancyPageBaseURL="http://healthspancareers.co.uk//vacancies/vacancy/"
                    ReturnToSearchURL="http://healthspancareers.co.uk//vacancies/"
                    contentDataData={{}}
                    location={false}
                    region={false}
                    category={true}
                    industry={false}
                    jobType={false}
                    jobTime={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </Layout>
)

export default NotFoundPage
